import { useFetcher } from '@remix-run/react'
import type { ZodTypeAny, z } from 'zod'

type UseOptimisticValueProps<T extends ZodTypeAny> = {
  fetcherKey: string
  key: string
  defaultValue?: z.infer<T>
  schema?: T
}

export function useOptimisticValue<T extends ZodTypeAny>({
  fetcherKey,
  key,
  defaultValue,
  schema
}: UseOptimisticValueProps<T> & { schema: T }): z.infer<T>

export function useOptimisticValue<T extends ZodTypeAny>({
  fetcherKey,
  key,
  defaultValue,
  schema
}: UseOptimisticValueProps<T> & { schema: never }): string

export function useOptimisticValue<T extends ZodTypeAny>({
  fetcherKey,
  key,
  defaultValue,
  schema
}: UseOptimisticValueProps<T>) {
  const fetcher = useFetcher<unknown>({ key: fetcherKey })

  const optimisticValue = fetcher.formData?.get(key)

  if (optimisticValue) {
    return schema ? schema.parse(optimisticValue) : optimisticValue
  }

  return defaultValue
}
